import { Badge, Tab, Tabs } from "@mui/material";
import { FC } from "react";
import { isMobile } from "react-device-detect";

export interface TabData {
    id: number;
    title: string;
    iconUrl?: string;
    path?: string;
    disabled?: boolean;
    showBadge?: boolean;
}

export interface BadgedTabsProps {
    tabs: TabData[];
    selectedTabIndex?: number;
    tabChanged?: Function;
}

const BadgedTabs: FC<BadgedTabsProps> = (props: BadgedTabsProps) => {
    const handleTabChange = (e: any, value: number) => {
        if (props.tabChanged) props.tabChanged(value);
    };

    return (
        <div className="badged-tabs ps-0 pe-0">
            <Tabs value={props.selectedTabIndex || 0} variant="scrollable" allowScrollButtonsMobile onChange={handleTabChange} aria-label="Dashboard tabs">
                {props.tabs.map((tab: TabData, i: number) => (
                    <Tab
                        value={tab.id || i}
                        disabled={tab.disabled}
                        label={
                            <Badge color="error" variant="dot" invisible={!tab.showBadge}>
                                {isMobile && (
                                    <div>
                                        {tab.iconUrl && <img alt={tab.title} src={tab.iconUrl} style={{ marginBottom: "5px", height: "20px" }} />}
                                        <div>{tab.title}</div>
                                    </div>
                                )}
                                {!isMobile && (
                                    <>
                                        {tab.iconUrl && <img alt={tab.title} src={tab.iconUrl} style={{ marginRight: "5px", height: "20px" }} />}
                                        {tab.title}
                                    </>
                                )}
                                {tab.showBadge && <>&nbsp;&nbsp;</>}
                            </Badge>
                        }
                        key={i}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default BadgedTabs;
